export default function hamburgerMenu() {
  const buttonElement = document.querySelector('.js-hamburger');
  const targetMenuElement = buttonElement
    ? document.querySelector(buttonElement.dataset.targetMenu)
    : null;
  const toggleState = () => {
    buttonElement.classList.toggle('active');
    targetMenuElement.classList.toggle('active');
  };

  if (!(buttonElement && targetMenuElement)) return;

  buttonElement.addEventListener('click', (event) => {
    event.preventDefault();
    toggleState();
  });
  targetMenuElement.addEventListener('click', (event) => {
    if (
      event.target.closest('a[href]') !== null &&
      targetMenuElement.classList.contains('active')
    ) {
      toggleState();
    }
  });
}
